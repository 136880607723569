import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';


const initialState = {
    activeDir: 'ltr',
    activeNavbarBg: '#0b70fb', // This can be any color,
    activeSidebarBg: '#ffffff', // This can be any color
    activeMode: 'light', // This can be light or dark
    activeTheme: 'ORANGE_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
};

const UiSlice = createSlice({
    name: "ui-slice",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },
        setTheme: (state, action) => {
            state.activeTheme = action.payload
        },
        setDarkMode: (state, action) => {
            console.log(action)
            state.activeMode = action.payload
        },
        setDir: (state, action) => {
            state.activeDir = action.payload
        },

    },
    extraReducers: {}
})

const { reducer } = UiSlice;
export const { clear, setTheme, setDarkMode, setDir } = UiSlice.actions;
export default reducer;