import { configureStore } from '@reduxjs/toolkit';
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import UiReducer from './slices/ui.slice';

// eslint-disable-next-line import/prefer-default-export
// export function configureStore(InitialState) {
//   const Store = createStore(
//     RootReducers,
//     InitialState,
//     composeWithDevTools(applyMiddleware(thunk)),
//   );
//   return Store;
// }

export const store = configureStore({
  reducer: {
    ui_store: UiReducer,
  },
});
